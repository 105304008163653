import firebase from 'firebase';

import { getNewDocument } from 'state/api/firestore';
import { createDocument } from 'state/api';

const checkApplicationsUniqueValues = async (
  db,
  { appId, name, secret, domain }
) => {
  const firestoreRef = db.collection('applications');
  const applicationsWithSameNameJob = firestoreRef
    .where('name', '==', name)
    .get();

  let applicationsWithSameSecretJob;

  if (secret) {
    applicationsWithSameSecretJob = firestoreRef
      .where('secret', '==', secret)
      .get();
  }

  let applicationsWithSameDomainJob;

  if (domain) {
    applicationsWithSameDomainJob = firestoreRef
      .where('domain', '==', domain)
      .get();
  }

  const [
    applicationsWithSameName,
    applicationsWithSameSecret,
    applicationsWithSameDomain,
  ] = await Promise.all([
    applicationsWithSameNameJob,
    applicationsWithSameSecretJob,
    applicationsWithSameDomainJob,
  ]);

  let sameNameThreshold = 0;
  let sameSecretThreshold = 0;
  let sameDomainThreshold = 0;

  if (appId) {
    applicationsWithSameName.forEach(({ id }) => {
      if (id === appId) {
        sameNameThreshold = 1;
      }
    });
    applicationsWithSameSecret?.forEach(({ id }) => {
      if (id === appId) {
        sameSecretThreshold = 1;
      }
    });
    applicationsWithSameDomain?.forEach(({ id }) => {
      if (id === appId) {
        sameDomainThreshold = 1;
      }
    });
  }

  const nameAlreadyExists = applicationsWithSameName.size > sameNameThreshold;
  const secretAlreadyExists =
    applicationsWithSameSecret?.size > sameSecretThreshold;
  const domainAlreadyExists =
    applicationsWithSameDomain?.size > sameDomainThreshold;

  if (nameAlreadyExists || secretAlreadyExists || domainAlreadyExists) {
    const error = Error(
      // eslint-disable-next-line no-nested-ternary
      nameAlreadyExists
        ? 'Applications.valuesMustBeUnique'
        : secretAlreadyExists
        ? 'Applications.secretMustBeUnique'
        : 'Applications.domainMustBeUnique'
    );
    error.code = 409;
    throw error;
  }
};

export const createApplicationDbTask = async (applicationData) => {
  const doc = await getNewDocument('applications');
  await createDocument(undefined, undefined, applicationData, doc);
  return applicationData;
};

export const createApplicationWithCheck = async ({
  name,
  domain,
  active,
  createdAt,
  lookAndFeelId,
  type,
  hasSMS,
  hasCustomLabels,
  underMaintenance,
  owner,
  netsuiteNotificationEndpoint,
  secret,
}) => {
  const db = firebase.firestore();
  const applicationData = {
    name,
    ...(domain && { domain }),
    active,
    createdAt,
    ...(lookAndFeelId && { lookAndFeelId }),
    type,
    ...(hasSMS && { hasSMS }),
    ...(hasCustomLabels && { hasCustomLabels }),
    ...(underMaintenance && { underMaintenance }),
    owner,
    ...(netsuiteNotificationEndpoint && { netsuiteNotificationEndpoint }),
    ...(secret && { secret }),
  };
  const application = await db.runTransaction(async () => {
    await checkApplicationsUniqueValues(db, applicationData);
    return createApplicationDbTask(applicationData);
  });
  return application;
};

export const updateApplicationWithCheck = async ({
  name,
  domain,
  active,
  createdAt,
  lookAndFeelId,
  type,
  hasSMS = false,
  hasCustomLabels = false,
  underMaintenance = false,
  appId,
  netsuiteNotificationEndpoint,
  secret,
  productionKey,
}) => {
  const db = firebase.firestore();
  const applicationData = {
    name,
    ...(domain && { domain }),
    active,
    createdAt,
    ...(lookAndFeelId && { lookAndFeelId }),
    type,
    hasSMS,
    hasCustomLabels,
    underMaintenance,
    ...(netsuiteNotificationEndpoint && { netsuiteNotificationEndpoint }),
    ...(secret && { secret }),
    ...(productionKey && { productionKey }),
  };

  await db.runTransaction(async () => {
    await checkApplicationsUniqueValues(db, { ...applicationData, appId });
    const applicationDoc = db.collection('applications').doc(appId);
    await applicationDoc.update(applicationData);
  });

  return applicationData;
};
