import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import { fetchCollection } from 'state/api';
import {
  createApplicationWithCheck,
  updateApplicationWithCheck,
} from 'services/applicationService';
import {
  APPLICATIONS_FETCH_DATA_INIT,
  APPLICATIONS_FETCH_DATA_SUCCESS,
  APPLICATIONS_FETCH_DATA_FAIL,
  APPLICATIONS_CREATE_APPLICATION_INIT,
  APPLICATIONS_CREATE_APPLICATION_SUCCESS,
  APPLICATIONS_CREATE_APPLICATION_FAIL,
  APPLICATIONS_MODIFY_APPLICATION_FAIL,
  APPLICATIONS_MODIFY_APPLICATION_INIT,
  APPLICATIONS_MODIFY_APPLICATION_SUCCESS,
} from 'state/actionCreators/applications';

export const fetchApplications = (id) => {
  return async (dispatch) => {
    dispatch(APPLICATIONS_FETCH_DATA_INIT());

    let applications;

    try {
      applications = await fetchCollection('applications', {
        queries: [{ attribute: 'owner', operator: '==', value: id }],
      });
    } catch (error) {
      toastr.error('Error fetching applications', error);
      return dispatch(APPLICATIONS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      APPLICATIONS_FETCH_DATA_SUCCESS({
        data: applications,
      })
    );
  };
};

export const createApplication =
  ({
    name,
    domain,
    active,
    createdAt,
    lookAndFeelId,
    type,
    hasSMS,
    hasCustomLabels,
    underMaintenance,
    id,
    netsuiteNotificationEndpoint,
    secret,
  }) =>
  async (dispatch, getState) => {
    dispatch(APPLICATIONS_CREATE_APPLICATION_INIT());
    const { locale } = getState().preferences;

    let applicationData;
    try {
      applicationData = await createApplicationWithCheck({
        name,
        domain,
        active,
        createdAt,
        lookAndFeelId,
        type,
        hasSMS,
        hasCustomLabels,
        underMaintenance,
        owner: id,
        netsuiteNotificationEndpoint,
        secret,
      });

      toastr.success('', 'Application created successfully');

      return dispatch(
        APPLICATIONS_CREATE_APPLICATION_SUCCESS({
          application: applicationData,
        })
      );
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(
        APPLICATIONS_CREATE_APPLICATION_FAIL({
          error: errorMessage,
        })
      );
    }
  };

export const modifyApplication = (modifiedApplication) => {
  return async (dispatch, getState) => {
    dispatch(APPLICATIONS_MODIFY_APPLICATION_INIT());
    const { locale } = getState().preferences;

    try {
      const { appId, ...application } = await updateApplicationWithCheck(
        modifiedApplication
      );

      toastr.success('', 'Application updated successfully');

      return dispatch(
        APPLICATIONS_MODIFY_APPLICATION_SUCCESS({
          application,
          appId,
        })
      );
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(
        APPLICATIONS_MODIFY_APPLICATION_FAIL({
          error: errorMessage,
        })
      );
    }
  };
};
